.scrollRemoveBox {
  overflow: scroll;
  -ms-overflow-style: none;
  /* 익스플로러, 앳지 */
  scrollbar-width: none;
  /* 파이어폭스 */
}

.scrollRemoveBox::-webkit-scrollbar {
  display: none;
  /* 크롬, 사파리, 오페라 */
}